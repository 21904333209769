<template>
  <div class="markup-tables flex">
    <va-card class="flex mb-4">
      <va-card-content>
        <va-breadcrumbs>
          <va-breadcrumbs-item :label="$t('common.list')" target="/property" to="/property" />
          <va-breadcrumbs-item :label="$t('common.detail')" disabled />
        </va-breadcrumbs>
        <div class="table-wrapper mt-4">
          <page-title :pageTitle="$t('property.read_property')" />
          <va-inner-loading :loading="loading" color="primary" class="va-loading" :size="60" v-if="loading" />
          <va-card :color="color" gradient v-if="displayRentability" class="mb-4">
            <va-card-title>
              {{ $t('property.rentability') }}
            </va-card-title>
            <va-card-content v-if="!loading && !isEmpty(property)">
              <div class="row">
                <div class="flex xs12 lg3 align--center justify--center" style="display: flex">
                  <div class="item">
                    <div class="rentability-title mb-2">
                      <va-icon name="calendar_today" class="mr-1" />
                      <strong>{{ $t('property.created_at') }}</strong>
                    </div>
                    <p>{{ formatDate(property.createdAt) }}</p>
                  </div>
                </div>
                <div class="flex xs12 lg3 align--center justify--center" style="display: flex">
                  <div class="item">
                    <div class="rentability-title mb-2">
                      <va-icon name="user" class="mr-1" />
                      <strong>{{ $t('property.created_by') }}</strong>
                    </div>
                    <p>{{ property.createdBy.fullName }}</p>
                  </div>
                </div>
                <div class="flex xs12 lg6">
                  <div class="item" style="text-align: center">
                    <div class="rentability-result">
                      <span>{{ property.rentability + '%' }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </va-card-content>
          </va-card>
          <property-form v-if="!loading && !isEmpty(property)" :readOnly="true" :initialData="property" />
        </div>
      </va-card-content>
    </va-card>
  </div>
</template>

<script>
import { GET_PROPERTY } from '@mdb/core/src';
import PropertyForm from './PropertyForm.vue';
import PageTitle from '@/components/text/PageTitle';
import { useGetEntity } from '@/components/helpers/useGetEntity';
import { useQuery, useResult } from '@vue/apollo-composable';
import { useRoute, useRouter } from 'vue-router';
import AuthenticationService from '@/components/helpers/AuthenticationService';
import { formatDate } from '@/components/helpers/Column';
import { computed, ref, watch } from 'vue';
import { isEmpty } from 'lodash';
export default {
  components: { PropertyForm, PageTitle },
  setup(props) {
    const route = useRoute();
    const router = useRouter();

    const { item, result, loading, error, notFound } = useGetEntity({ id: route.params.id }, GET_PROPERTY);

    const displayRentability = !AuthenticationService.isAgency();

    const getColor = (item) => {
      if (+item.rentability < 20) {
        return 'danger';
      }
      if (+item.rentability > 25) {
        return 'success';
      }
      return 'warning';
    };
    const color = ref('');

    watch(
      item,
      (newItem) => {
        color.value = getColor(newItem);
      },
      { immediate: true }
    );

    return {
      error,
      property: item,
      id: route.params.id,
      loading,
      displayRentability,
      formatDate,
      color,
      isEmpty,
    };
  },
};
</script>

<style lang="scss">
.rentability-title,
.rentability-card {
  display: flex;
}
.rentability-result {
  font-weight: 700;
  font-size: 50px;
  color: var(--va-light-gray);
  background-color: var(--va-hover-black);
  display: inline-block;
  line-height: 0px;
  border-radius: 50%;
}
.rentability-result span {
  display: inline-block;
  padding-top: 50%;
  padding-bottom: 50%;
  margin-left: 8px;
  margin-right: 8px;
}
</style>
